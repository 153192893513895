<template>

    <section class="w-full grid grid-cols-1 md:grid-cols-2 gap-4 mt-6 items-center justify-center my-24">
        <img src="./../assets/security-verifications2.png" height="200" title="Verified Security" />
        <div class="p-4 w-full md:w-[400px] flex flex-col space-y-6 mx-auto">
            <h3 class="text-left text-aspect uppercase tracking-widest font-black">Verified Security</h3>
            <p class="text-black">DeFi Terminal is built on a <strong>robust set of liquidity mining and staking protocols</strong> that are audited by <strong>leading auditing firms</strong> and are verified to be secure.</p>
            <p class="text-black">Our smart contracts regularly go through internal and external auditing, staying true to our commitment to security across our solutions.</p>
        </div>
    </section>

</template>

<script>

export default {
    name: 'VerifiedSecurity',
    components: {

    }
}

</script>

<style scoped>

</style>
