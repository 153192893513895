<template>

    <section class="w-full my-24 p-4 lg:p-0">
        <HeadingDecent title="Secure Smart Contracts" />
        <div class="w-[900px] max-w-full mx-auto mt-2">
            <HeadingSecondary title="Verified Security" />
        </div>
        <p class="w-[600px] max-w-full mx-auto text-decent text-center mt-2" style="font-size: 1.2rem;">DeFi Terminal is built on a <strong>robust set of liquidity mining and staking protocols</strong> that are audited by <strong>leading auditing firms</strong> and are verified to be secure.</p>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6 items-center">
            <p class="text-decent max-w-[415px] mx-auto">Our smart contracts regularly go through internal and external auditing, staying true to our commitment to security across our solutions.<br ><br />You can also take advantage of multi-signature authentication, making sure that campaigns deployed are authorized by multiple users and wallets in a decentralized way.<br /><br />You have complete ownership over your smart contracts—we don't even have access to your campaigns.</p>
            <div class="flex justify-center">
                <img class="block" src="./../assets/security-verifications2.png" />
            </div>
        </div>
    </section>

</template>

<script>

import HeadingDecent from './HeadingDecent.vue';
import HeadingSecondary from './HeadingSecondary.vue';

export default {
    name: 'SecuritySection',
    components: {
        HeadingDecent,
        HeadingSecondary,
    }
}

</script>

<style scoped>



</style>
