<template>

    <section class="w-full my-24">
        <HeadingSecondary title="Tutorials" />
        <div class="w-full mt-12 grid grid-cols-2 md:grid-cols-4 gap-8" v-if="campaign === 'build'">
            <tutorial-tile :image="require('@/assets/video-thumbnails/build1.webp')"
                            title="Step 1: Creating your account"
                            description="In step one, you will be creating your personal account on the DeFi Terminal."
                            url="https://www.youtube.com/watch?v=Xew8lTe6iOQ&list=PLFzGoeRPalSbxp2BVskD1ljeEwvRIz3_N&index=1" />
            <tutorial-tile :image="require('@/assets/video-thumbnails/build2.webp')"
                            title="Step 2: Setting up your project"
                            description="In step two, you will be setting up your project by providing basic information about it."
                            url="https://www.youtube.com/watch?v=sWKhlruVmQ0&list=PLFzGoeRPalSbxp2BVskD1ljeEwvRIz3_N&index=2" />
            <!--<tutorial-tile :image="require('@/assets/video-thumbnails/build3.jpg')"
                            title="Step 3: Subscribing to LMaas"
                            description="In step three, you will be subscribing to LMaaS to get full access to the admin dashboard."
                            url="https://www.youtube.com/watch?v=Gl0TE0YILcw&list=PLFzGoeRPalSbxp2BVskD1ljeEwvRIz3_N&index=3" />-->
            <!--<tutorial-tile :image="require('@/assets/video-thumbnails/build3.jpg')"
                            title="Step 3: Subscription"
                            description="This video will be updated soon to reflect the new payment portal in DeFi terminal. Stay tuned!" />-->
            <tutorial-tile :image="require('@/assets/video-thumbnails/build4.webp')"
                            title="Step 3: Deploying your project"
                            description="In step four, you will be setting up your project administrators, and preparing for deployment."
                            url="https://www.youtube.com/watch?v=s83-XgS5WOc&list=PLFzGoeRPalSbxp2BVskD1ljeEwvRIz3_N&index=4" />
            <tutorial-tile :image="require('@/assets/video-thumbnails/build5.webp')"
                            title="Step 4: Customizing your project"
                            description="In step five, you will be customizing your project by adding your logo, selecting colors, and additional information."
                            url="https://www.youtube.com/watch?v=tlXucMQOWq0&list=PLFzGoeRPalSbxp2BVskD1ljeEwvRIz3_N&index=5" />
        </div>
        <div class="w-full mt-12 grid grid-cols-2 md:grid-cols-4 gap-8" v-if="campaign === 'engage'">
            <tutorial-tile :image="require('@/assets/video-thumbnails/engage1.webp')"
                            title="Getting Started to Participate in Campaigns on the DeFi Terminal"
                            description="In this video, you will learn about the prerequisites for joining campaigns."
                            url="https://www.youtube.com/watch?v=jCLYe7QL_ys&list=PLFzGoeRPalSbqybW680FJj8kLveMgiIVp&index=1" />
            <tutorial-tile :image="require('@/assets/video-thumbnails/engage2.webp')"
                            title="Providing Liquidity Through Liquidity Mining"
                            description="In this video, you will learn how to participate in liquidity mining campaigns by providing liquidity and staking your LP tokens."
                            url="https://www.youtube.com/watch?v=pbRbQa-_qQo&list=PLFzGoeRPalSbqybW680FJj8kLveMgiIVp&index=2" />
            <tutorial-tile :image="require('@/assets/video-thumbnails/engage3.webp')"
                            title="Staking Assets Through Single-Sided Staking"
                            description="In this video, you will learn how to participate in single-sided staking campaigns by staking your tokens."
                            url="https://www.youtube.com/watch?v=Kh5IP9dYx74&list=PLFzGoeRPalSbqybW680FJj8kLveMgiIVp&index=3" />
            <tutorial-tile :image="require('@/assets/video-thumbnails/engage4.webp')"
                            title="Understanding Metrics in the DeFi Terminal Portal"
                            description="In this video, you will learn how to understand the metrics for campaigns that you participate in, as shown in the DeFi Terminal portal."
                            url="https://www.youtube.com/watch?v=5sqTGFrlqzY&list=PLFzGoeRPalSbqybW680FJj8kLveMgiIVp&index=4" />
        </div>
    </section>

</template>

<script>

import HeadingSecondary from './HeadingSecondary.vue';
import TutorialTile from './TutorialTile.vue';

export default {
    name: 'TutorialsList',
    props: [
        'campaign',
    ],
    components: {
        HeadingSecondary,
        TutorialTile,
    }
}

</script>

<style scoped>

</style>
