<template>
    <img class="absolute h-auto w-full top-0 right-0 -z-1 object-cover max-w-[1200px]" src="./../assets/blurred-background.png" />
    <div id="hubspot-form" class="my-12 w-[700px] max-w-full mx-auto p-8 shadow rounded-lg bg-white/90 hidden" v-once>
        <!--<div id="hubspot-loader" class="w-full py-32 flex justify-center">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>-->
    </div>
</template>

<script>

import { event } from 'vue-gtag';
import { useMeta } from 'vue-meta';

export default {
    name: 'PageContact',
    components: {

    },
    data() {
        return {
            emailInserted: false,
            newsletterCheckBoxToggled: false,
        }
    },
    async mounted() {
        event('page_view', {
            page_title: 'defiterm.io',
            page_location: 'contact',
            page_path: 'https://defiterm.io/contact',
            send_to: 'G-DHZJ64L3XS'
        });

        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        await script.addEventListener('load', async () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: 'na1',
                    portalId: '20292700',
                    formId: '90da1c8d-1450-4040-a978-caa31fce378a',
                    target: '#hubspot-form',
                });
            }
        });

        /*await function() {
            return new Promise(resolve => setTimeout(resolve, 500));
        };*/

        // document.getElementById('hubspot-loader').remove();
        if (document.getElementById('hubspot-form').classList.contains('hidden')) {
            document.getElementById('hubspot-form').classList.remove('hidden');
        }

        this.toggleNewsletterCheckbox();

        if (this.$route.params.email && this.$route.params.email.length > 0) {
            this.insertEmail();
        }
    },
    setup() {
        useMeta({
            title: 'Contact us',
        });
    },
    methods: {
        insertEmail: function() {
            if (!this.emailInserted) {
                const iframe = document.querySelectorAll('iframe[class="hs-form-iframe"]')[0];

                if (iframe && iframe.contentWindow.document.querySelectorAll('input[type=email]')[0]) {
                    iframe.contentWindow.document.querySelectorAll('input[type=email]')[0].value = this.$route.params.email;
                    this.emailInserted = true;
                } else {
                    window.setTimeout(this.insertEmail(), 500);
                }
            }
        },
        toggleNewsletterCheckbox: function() {
            if (!this.newsletterCheckBoxToggled) {
                const iframe = document.querySelectorAll('iframe[class="hs-form-iframe"]')[0];
                console.log(iframe);
                if (iframe && iframe.contentWindow.document.querySelectorAll('input[type=checkbox]')[0]) {
                    iframe.contentWindow.document.querySelectorAll('input[type=checkbox]')[0].click();
                    this.newsletterCheckBoxToggled = true;
                } else {
                    window.setTimeout(this.toggleNewsletterCheckbox(), 500);
                }
            }
        }
    }
}

</script>

<style scoped>

</style>
