<template>

    <section class="w-full my-24 p-4 lg:p-0">
        <HeadingDecent title="The Opportunity" />
        <div class="w-[900px] max-w-full mx-auto mt-2">
            <HeadingSecondary title="DeFi Terminal makes it easy to launch liquidity mining and staking campaigns" />
        </div>
        <p class="w-[600px] max-w-full mx-auto text-decent text-center mt-2" style="font-size: 1.2rem;">Our no-code solution and SDK make it easy to launch your campaigns. <strong class="font-bold">DeFi Terminal does the heavy lifting</strong> for you so that <strong class="font-bold">you can focus on building your product and your community.</strong></p>
        <div class="grid grid-cols-1 md:grid-cols-2 justify-center items-center md:items-start gap-4 md:space-y-0 md:gap-8 mt-6">
            <div class="p-4">
                <!--<HeadingDecent title="For Projects" />-->
                <div class="grid grid-cols-2 gap-4 mt-2">
                    <ImagePanel title="Easy setup and launch" description="Create your campaigns using our no-code solution and launch in an hour" :image="require('@/assets/opportunity1.png')" />
                    <ImagePanel title="Your Branded Campaigns" description="Customize your campaign portal with your logo and brand colors" :image="require('@/assets/opportunity2.png')" />
                </div>
            </div>
            <div class="p-4">
                <!--<HeadingDecent title="For Users" />-->
                <div class="grid grid-cols-2 gap-4 mt-2">
                    <ImagePanel title="Secure Smart Contracts" description="Deploy your campaigns with secure and audited smart contracts, which you fully own" :image="require('@/assets/opportunity3.png')" />
                    <ImagePanel title="Deep integration for agile development" description="Integrate liquidity mining and staking protocols directly in your app with our SDK" :image="require('@/assets/opportunity4.png')" />
                </div>
            </div>
        </div>
    </section>

</template>

<script>

import HeadingDecent from './HeadingDecent.vue';
import HeadingSecondary from './HeadingSecondary.vue';
import ImagePanel from './ImagePanel.vue';

export default {
    name: 'OpportunitySection',
    components: {
        HeadingDecent,
        HeadingSecondary,
        ImagePanel,
    }
}

</script>

<style scoped>



</style>
