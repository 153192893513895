<template>

    <section class="w-full my-24">
        <HeadingSecondary title="Networks and DEXes supported" />
        <div class="data-table__disclaimer my-6">
            <p class="data-table__disclaimer-text">Use the <span class="data-table__disclaimer-text--highlighted">AllianceBlock Bridge</span> to bridge tokens across our supported networks.</p>
            <a href="https://alliancebridge.io/" target="_blank">
                <button class="button-default button--secondary global-hover box-secondary">AllianceBlock Bridge</button>
            </a>
        </div>
        <div class="flex flex-col space-y-8">
            <div class="mx-auto flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-8 justify-center items-center">
                <img class="h-12 md:h-6 lg:h-8 2xl:h-12" src="./../assets/third-party-logos/ethereum.png" alt="Ethereum" />
                <img class="h-12 md:h-6 lg:h-8 2xl:h-12" src="./../assets/third-party-logos/bnb-chain.png" alt="BNB Chain" />
                <img class="h-12 md:h-6 lg:h-8 2xl:h-12" src="./../assets/third-party-logos/polygon.png" alt="Polygon" />
                <img class="h-12 md:h-6 lg:h-8 2xl:h-12" src="./../assets/third-party-logos/avalanche.png" alt="Avalanche" />
            </div>
            <div class="mx-auto flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-8 justify-center items-center">
                <img class="h-12 md:h-6 lg:h-8 2xl:h-12" src="./../assets/third-party-logos/energyweb.png" alt="Energy Web" />
                <img class="h-12 md:h-6 lg:h-8 2xl:h-12" src="./../assets/third-party-logos/moonbeam.png" alt="Moonbeam" />
            </div>
            <div class="mx-auto flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-8 justify-center items-center">
                <img class="h-12 md:h-6 lg:h-8 2xl:h-12" src="./../assets/third-party-logos/uniswap.png" alt="Uniswap" />
                <img class="h-12 md:h-6 lg:h-8 2xl:h-12" src="./../assets/third-party-logos/balancer.png" alt="Balancer" />
                <img class="h-12 md:h-6 lg:h-8 2xl:h-12" src="./../assets/third-party-logos/pancakeswap.png" alt="PancakeSwap" />
                <img class="h-12 md:h-6 lg:h-8 2xl:h-12" src="./../assets/third-party-logos/pangolin.png" alt="Pangolin" />
            </div>
            <div class="mx-auto flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-8 justify-center items-center">
                <img class="h-12 md:h-6 lg:h-8 2xl:h-12" src="./../assets/third-party-logos/quickswap.png" alt="QuickSwap" />
                <img class="h-12 md:h-6 lg:h-8 2xl:h-12" src="./../assets/third-party-logos/solarflare.svg" alt="Solarflare" />
                <img class="h-12 md:h-6 lg:h-8 2xl:h-12" src="./../assets/third-party-logos/traderjoe.svg" alt="TraderJoe" />
                <img class="h-12 md:h-6 lg:h-8 2xl:h-12" src="./../assets/third-party-logos/allianceblock-dex.png" alt="AllianceBlock DEX" />
            </div>
        </div>
    </section>

</template>

<script>

import HeadingSecondary from './HeadingSecondary.vue';

export default {
    name: 'ChainsAndDexes',
    components: {
        HeadingSecondary,
    }
}

</script>

<style scoped>

.data-table__disclaimer {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.data-table__disclaimer-text {
    text-align: center;
    color: #586889
}

.data-table__disclaimer-text--highlighted {
    color: #5466FD;
}

.data-table__disclaimer-button {
    padding: 7.5px 10px;
    background: #FFFFFF;
    color: #5466FD;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 10px;
    border: 0;
    outline: none;
    cursor: pointer;
    transition: all 0.1s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.15);
}

.data-table__disclaimer-button:hover {
    background: #5466FD;
    color: #FFFFFF;
}

@media only screen and (min-width: 600px) {
    .data-table__disclaimer {
        flex-direction: row;
    }
}

</style>
