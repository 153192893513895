<template>

    <section class="w-full relative my-6 pb-8 flex overflow-x-auto snap-mandatory snap-x gap-6 items-center">
        <TestimonialTile :image="require('@/assets/third-party-logos/ore-network.png')" value1="10" value1Label="Liquidity mining and staking campaigns launched" value2="3x" value2Label="Liquidity raised through the campaigns" text="After the successful launch of the ORE Token Initial DEX Offering our protocol participation is rapidly expanding. The overall growth in activity across Ethereum, Algorand, Polygon and more showcase the clear need for a fast and simple-to-use cross-chain solution, like ORE. None of this would be possible without the support of the ORE community. By using AllianceBlock’s Liquidity Mining as a Service function within their DeFi Terminal platform, we have been able to increase our liquidity and hence strengthen the ORE Token." author="Marc Blinder" authorPosition="AIKON CEO and ORE Core Contributor" />
        <TestimonialTile :image="require('@/assets/third-party-logos/glitch.png')" value1="10" value1Label="Liquidity mining and staking campaigns launched" value2="3x" value2Label="Liquidity raised through the campaigns" text="It has been incredible to see the development of the DeFi Terminal to facilitate a variety of use cases in recent months. We’re proud to be the first layer one blockchain using the solution and to see such great results in the staking campaigns for our native token GLCH." author="Sean Ryan" authorPosition="CEO" />
        <TestimonialTile :image="require('@/assets/third-party-logos/crypto-xpress.png')" value1="10" value1Label="Liquidity mining and staking campaigns launched" value2="3x" value2Label="Liquidity raised through the campaigns" text="The DeFi Terminal enabled us to launch a campaign to reward our stakers through allocations that complimented our ongoing NFT drop strategy. This helped us to stay on top of the community sentiment and add an extra incentive for our followers to participate in our NFT offerings." author="Yogesh Panjabi" authorPosition="Co-founder and CEO" />
        <TestimonialTile :image="require('@/assets/third-party-logos/vault-hill.png')" value1="10" value1Label="Liquidity mining and staking campaigns launched" value2="3x" value2Label="Liquidity raised through the campaigns" text="Every project needs to put its community front and centre in order to thrive. AllianceBlock’s DeFi Terminal allows us to run seamless staking campaigns time and time again with Vault Hill’s brand and colours. The backend and frontend together drastically improve the experience for us and our community." author="Jimi Daodu" authorPosition="Founder and CEO" />
        <TestimonialTile :image="require('@/assets/third-party-logos/lokr.png')" value1="10" value1Label="Liquidity mining and staking campaigns launched" value2="3x" value2Label="Liquidity raised through the campaigns" text="Our first campaign on the Ethereum chain was a big success for us. We are looking forward to the continued usage of these services. It provides a great way for our community to support our project and, in return, they are rewarded." author="Anis Ahmed Chohan" authorPosition="CTO and Co-Founder" />
        <TestimonialTile :image="require('@/assets/third-party-logos/dafi.png')" value1="10" value1Label="Liquidity mining and staking campaigns launched" value2="3x" value2Label="Liquidity raised through the campaigns" text="The AllianceBlock DeFi Terminal allowed us to launch a liquidity program without sacrificing our own development bandwidth. This helps us scale, and focus on our own products, whilst still launching a user-friendly program." author="Zain Rana" authorPosition="Founder · Dafi Protocol" />
    </section>

</template>

<script>

import TestimonialTile from './TestimonialTile.vue';

export default {
    name: 'TestimonialsList',
    components: {
        TestimonialTile,
    }
}

</script>

<style scoped>

</style>
