<template>

    <section class="w-full my-24 p-4 lg:p-0">
        <HeadingDecent title="Improve Liquidity" />
        <div class="w-[900px] max-w-full mx-auto mt-2">
            <HeadingSecondary title="Improve liquidity through liquidity mining and single-sided staking campaigns" />
        </div>
        <p class="w-[600px] max-w-full mx-auto text-decent text-center mt-2" style="font-size: 1.2rem;">Liquidity mining and single-sided staking campaigns <strong>keep your token stable and improve your liquidity</strong> on exchanges. Raising and locking liquidity is essential to <strong>stabilize volatility</strong> for your token and <strong>minimize slippage</strong> when swapping.</p>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6 items-center">
            <p class="text-decent max-w-[415px] mx-auto">Through liquidity mining, you can ensure availability of your token on exchanges by offering additional rewards for your liquidity providers in addition to transaction fees they earn.<br /><br />Single-sided staking locks your liquidity by incentivizing token holders to stake their tokens for longer periods of time by providing rewards.<br /><br /><strong>These tools empower you and your project while building trust with your community.</strong></p>
            <img class="aspect-video w-full" src="./../assets/liquidity.png" />
        </div>
    </section>

</template>

<script>

import HeadingDecent from './HeadingDecent.vue';
import HeadingSecondary from './HeadingSecondary.vue';

export default {
    name: 'LiquiditySection',
    components: {
        HeadingDecent,
        HeadingSecondary,
    }
}

</script>

<style scoped>



</style>
