import { createWebHistory, createRouter } from "vue-router";

import PageBuild from "@/views/PageBuild";
import PageContact from "@/views/PageContact";
import PageIndex from "@/views/PageIndex";
import PageKnowMore from "@/views/PageKnowMore";
import PageNotFound from "@/views/PageNotFound";
import PageTerminal from "@/views/PageTerminal";

const routes = [
    {
        path: "/build",
        name: "build",
        component: PageBuild,
    },
    {
        path: "/contact-us/:email?",
        name: "contact",
        component: PageContact,
    },
    {
        path: "/",
        name: "index",
        component: PageIndex,
    },
    {
        path: "/terminal",
        name: "terminal",
        component: PageTerminal,
    },
    {
        path: "/know-more",
        name: "knowMore",
        component: PageKnowMore,
    },
    {
        path: "/:pathMatch(.*)*",
        name: "error",
        component: PageNotFound,
    }
];

const router = createRouter({
    routes,
    history: createWebHistory(),
    scrollBehavior(to/*, from, savedPosition*/) {
        if (to.hash) {
            /*return {
                el: to.hash,
            }*/
            //return { top: 0 }
        } else {
            return { top: 0 }
        }
    }
});

export default router;
