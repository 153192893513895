<template>

    <section id="problem" class="w-full my-24 p-4 lg:p-0 scroll-mt-36">
        <HeadingDecent title="The Problem" />
        <div class="w-[900px] max-w-full mx-auto mt-2">
            <HeadingSecondary title="It’s difficult to launch liquidity mining and staking programs" />
        </div>
        <p class="w-[600px] max-w-full mx-auto text-decent text-center mt-2" style="font-size: 1.2rem;">Liquidity mining and staking are a focal point of any blockchain project with a token, but builders have to <strong>develop their own programs, taking away team resources, introducing potential security issues.</strong></p>
        <div class="grid grid-cols-1 md:grid-cols-2 justify-center items-center md:items-start gap-4 md:space-y-0 md:gap-8 mt-6">
            <div class="p-4">
                <!--<HeadingDecent title="For Projects" />-->
                <div class="grid grid-cols-2 gap-4 mt-2">
                    <ImagePanel title="It’s not easy to launch campaigns" description="Creating your own protocols adds to development time and team resources" :image="require('@/assets/problem1.png')" />
                    <ImagePanel title="Current tools take away your brand" description="Existing tools have limited customizability options to emphasize your brand" :image="require('@/assets/problem2.png')" />
                </div>
            </div>
            <div class="p-4">
                <!--<HeadingDecent title="For Users" />-->
                <div class="grid grid-cols-2 gap-4 mt-2">
                    <ImagePanel title="Custom programs can be vulnerable" description="Building your own contracts are costly and could introduce potential security issues" :image="require('@/assets/problem3.png')" />
                    <ImagePanel title="There’s no flexibility in integration" description="Third-party solutions can be difficult to build on and expand for your roadmap and goals" :image="require('@/assets/problem4.png')" />
                </div>
            </div>
        </div>
    </section>

</template>

<script>

import HeadingDecent from './HeadingDecent.vue';
import HeadingSecondary from './HeadingSecondary.vue';
import ImagePanel from './ImagePanel.vue';

export default {
    name: 'ProblemSection',
    components: {
        HeadingDecent,
        HeadingSecondary,
        ImagePanel,
    }
}

</script>

<style scoped>



</style>
