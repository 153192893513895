import { createApp } from 'vue';
// import { createSSRApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueGtag from 'vue-gtag';
import { createMetaManager, vueMetaPlugin, VueMeta } from 'vue-meta';
import './index.css';

const handleSupportWidgetVisibility = function(show) {
    const supportWidget = document.getElementById('freshworks-container');

    if (supportWidget) {
        if (show) {
            supportWidget.style.display = 'block';
        } else {
            supportWidget.style.display = 'none';
        }
    } else {
        setTimeout(function() {
            handleSupportWidgetVisibility(show);
        }, 100);
    }
};

const app = createApp(App);
// const app = createSSRApp(App);
const metaManager = createMetaManager();

router.beforeEach(async (to, from, next) => {
    if (to.name === 'knowMore') {
        handleSupportWidgetVisibility(0);
    } else {
        handleSupportWidgetVisibility(1);
    }

    next();
});

app.use(router);
app.use(VueMeta);
app.use(metaManager);
app.use(vueMetaPlugin);

if (process.env.VUE_APP_PATH && process.env.VUE_APP_PATH == 'https://defiterm.io') {
    app.use(VueGtag, {
        config: {
            id: 'G-DHZJ64L3XS',
            // UA-188585311 / UA-188585311-2
        }
    });
}

app.mount('#app');
