<template>
    <div class="bg-white min-h-full px-4 sm:px-6 py-24 md:py-64 md:grid md:place-items-center lg:px-8">
        <div class="max-w-max mx-auto">
            <main class="sm:flex">
                <p class="text-4xl font-extrabold text-aspect sm:text-5xl">404</p>
                <div class="sm:ml-6">
                    <div class="sm:border-l sm:border-gray-200 sm:pl-6">
                        <h1 class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Page not found</h1>
                        <p class="mt-1 text-base text-gray-500">Please check the URL in the address bar and try again.</p>
                    </div>
                    <div class="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                        <router-link :to="{name: 'index'}" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-aspect hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Go back home</router-link>
                        <!--<a href="#" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Contact support</a>-->
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>

import { useMeta } from 'vue-meta';

export default {
    name: 'PageNotFound',
    components: {

    },
    setup() {
        useMeta({
            title: 'Page not found',
        });
    },
}

</script>

<style scoped>

</style>
