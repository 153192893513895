<template>
    <img class="absolute h-auto w-full top-0 right-0 -z-1 object-cover max-w-[1200px]" src="./../assets/blurred-background.png" />
    <BuildOpener />
    <DataMetrics />
    <UseCases />
    <ProblemSection />
    <OpportunitySection />
    <TestimonialsList />
    <LiquiditySection />
    <CustomizationSection />
    <ChainsAndDexes />
    <SecuritySection />
    <ClientsList :showTextContent="true" />
    <div class="w-full mb-8">
        <HeadingDecent title="Current Campaigns" />
    </div>
    <DataTable :elementsPerPage="5" :showDisclaimer="false" />
    <TutorialsList campaign="build" />
    <CTABanner />
</template>

<script>

import BuildOpener from '../components/BuildOpener.vue';
import ChainsAndDexes from '../components/ChainsAndDexes.vue';
import ClientsList from '../components/ClientsList.vue';
import CTABanner from '../components/CTABanner.vue';
import CustomizationSection from '../components/CustomizationSection.vue';
import DataMetrics from '../components/DataMetrics.vue';
import DataTable from '../components/DataTable.vue';
import { event } from 'vue-gtag';
import HeadingDecent from '../components/HeadingDecent.vue';
import OpportunitySection from '../components/OpportunitySection.vue';
import LiquiditySection from '../components/LiquiditySection.vue';
import ProblemSection from '../components/ProblemSection.vue';
import SecuritySection from '../components/SecuritySection.vue';
import TestimonialsList from '../components/TestimonialsList.vue';
import TutorialsList from '../components/TutorialsList.vue';
import UseCases from '../components/UseCases.vue';
import { useMeta } from 'vue-meta';

export default {
    name: 'PageBuild',
    components: {
        BuildOpener,
        ChainsAndDexes,
        ClientsList,
        CTABanner,
        CustomizationSection,
        DataMetrics,
        DataTable,
        HeadingDecent,
        LiquiditySection,
        OpportunitySection,
        ProblemSection,
        SecuritySection,
        TestimonialsList,
        TutorialsList,
        UseCases,
    },
    mounted() {
        if (process.env.VUE_APP_PATH && process.env.VUE_APP_PATH == 'https://defiterm.io') {
            event('page_view', {
                page_title: 'defiterm.io',
                page_location: 'build',
                page_path: 'https://defiterm.io/build',
                send_to: 'G-DHZJ64L3XS'
            });
        }
    },
    setup() {
        useMeta({
            title: 'Build',
        });
    },
}

</script>

<style scoped>

</style>
