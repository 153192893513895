<template>
    <img class="absolute h-auto w-full top-0 right-0 -z-1 object-cover max-w-[1200px]" src="./../assets/blurred-background.png" />
    <MainOpener />
    <DataTable />
    <CTABanner />
    <DataMetrics />
    <ChainsAndDexes />
    <ClientsList />
    <VerifiedSecurity />
    <TutorialsList />
    <CTABanner />
</template>

<script>

import ChainsAndDexes from '../components/ChainsAndDexes.vue';
import ClientsList from '../components/ClientsList.vue';
import CTABanner from '../components/CTABanner.vue';
import DataMetrics from '../components/DataMetrics.vue';
import DataTable from '../components/DataTable.vue';
import MainOpener from '../components/MainOpener.vue';
import TutorialsList from '../components/TutorialsList.vue';
import { useMeta } from 'vue-meta';
import VerifiedSecurity from '../components/VerifiedSecurity.vue';

export default {
    name: 'PageTerminal',
    components: {
        ChainsAndDexes,
        ClientsList,
        CTABanner,
        DataMetrics,
        DataTable,
        MainOpener,
        TutorialsList,
        VerifiedSecurity,
    },
    setup() {
        useMeta({
            title: 'DeFi Terminal',
        });
    },
}

</script>

<style scoped>

</style>
