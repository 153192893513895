<template>

    <!--<div class="bg-gray-100" v-bind:class="menuOpen ? 'overflow-hidden' : ''">-->
    <header class="w-full top-0 left-0 relative z-500 sticky bg-white shadow">
        <!--<div class="w-full bg-[#5466FD] text-white p-2">
            <div class="max-w-7xl mx-auto flex items-center justify-between">
                <p>Lorem ipsum dolor sit amet.</p>
                <a href="#">
                    <button class="py-2 px-4 border border-white rounded-full">Read more</button>
                </a>
            </div>
        </div>-->
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-2 flex flex-col space-y-4" v-if="$route.name !== 'knowMore'">
            <div class="w-full flex items-center justify-between py-3">
                <a href="https://allianceblock.io">
                    <img src="./../assets/logo.png" class="h-[40px]" title="AllianceBlock" />
                </a>
                <nav class="hidden md:flex md:items-center" v-cloak>
                    <button class="inline-flex items-center p-3 text-sm tracking-widest font-bold text-gray-400 cursor-pointer transition relative group" @mouseover="submenu = 1" @mouseleave="submenu = 0">
                        <!--<a href="#">-->
                            <div class="transition group-hover:text-gray-600">About</div>
                        <!--</a>-->
                        <div class="absolute left-1/2 top-full transform -translate-x-1/2 transition z-500 min-w-[150px]" v-show="submenu == 1" x-transition.opacity v-cloak>
                            <div class="h-6 cursor-default"></div>
                            <ul class="py-1 px-2 bg-white border border-gray-300 rounded-lg shadow-lg flex flex-col justify-start items-start text-left whitespace-nowrap">
                                <li>
                                    <a href="https://allianceblock.io/ecosystem" class="py-1 transition hover:text-gray-600 block w-full">Ecosystem</a>
                                </li>
                                <li>
                                    <a href="https://allianceblock.io/team" class="py-1 transition hover:text-gray-600 block w-full">Team</a>
                                </li>
                                <li>
                                    <a href="https://allianceblock.io/token" class="py-1 transition hover:text-gray-600 block w-full">Token</a>
                                </li>
                                <li>
                                    <a href="https://allianceblock.io/use-cases" class="py-1 transition hover:text-gray-600 block w-full">Use cases</a>
                                </li>
                                <li>
                                    <a href="https://allianceblock.io/roadmap" class="py-1 transition hover:text-gray-600 block w-full">Roadmap</a>
                                </li>
                            </ul>
                        </div>
                    </button>
                    <button class="inline-flex items-center p-3 text-sm tracking-widest font-bold text-gray-400 cursor-pointer transition relative group" @mouseover="submenu = 2" @mouseleave="submenu = 0">
                        <a href="#">
                            <div class="transition group-hover:text-gray-600">Media</div>
                        </a>
                        <div class="absolute left-1/2 top-full transform -translate-x-1/2 transition z-500 min-w-[150px]" v-show="submenu == 2" x-transition.opacity v-cloak>
                            <div class="h-6 cursor-default"></div>
                            <ul class="py-1 px-2 bg-white border border-gray-300 rounded-lg shadow-lg flex flex-col justify-start items-start text-left whitespace-nowrap">
                                <li>
                                    <a href="https://allianceblock.io/news" class="py-1 transition hover:text-gray-600 block w-full">News</a>
                                </li>
                                <li>
                                    <a href="https://allianceblock.io/blogs" class="py-1 transition hover:text-gray-600 block w-full mb-4">Blogs</a>
                                </li>
                                <!--<li>
                                    <a href="#" class="py-1 transition hover:text-gray-600 block w-full">Brand</a>
                                </li>
                                <li>
                                    <a href="#" class="py-1 transition hover:text-gray-600 block w-full">Downloads</a>
                                </li>-->
                                <li>
                                    <div class="py-1 tracking-widest text-xs text-gray-700 uppercase cursor-default block w-full">Documentation</div>
                                </li>
                                <li>
                                    <a href="/files/AllianceBlock_Whitepaper.pdf" class="py-1 transition hover:text-gray-600 block w-full" target="_blank">White Paper</a>
                                </li>
                                <li>
                                    <a href="/files/AllianceBlock_DEX_Whitepaper.pdf" class="py-1 transition hover:text-gray-600 block w-full" target="_blank">DEX White Paper</a>
                                </li>
                                <li>
                                    <a href="/files/AllianceBlock_Greenpaper.pdf" class="py-1 transition hover:text-gray-600 block w-full" target="_blank">Green Paper</a>
                                </li>
                                <li>
                                    <a href="/files/AllianceBlock_Token_Economics_Paper.pdf" class="py-1 transition hover:text-gray-600 block w-full" target="_blank">Token Economics Paper</a>
                                </li>
                                <!--<li>
                                    <a href="https://allianceblock.io/AllianceBlock_OnePager.pdf" class="py-1 transition hover:text-gray-600 block w-full" target="_blank">One Pager</a>
                                </li>-->
                            </ul>
                        </div>
                    </button>
                    <button class="inline-flex items-center p-3 text-sm tracking-widest font-bold text-gray-400 cursor-pointer transition relative group" @mouseover="submenu = 3" @mouseleave="submenu = 0">
                        <!--<a href="#">-->
                            <div class="transition group-hover:text-gray-600" :class="{'text-gray-600': $route.name === 'index'}">Engage</div>
                            <!--<div class="transition group-hover:text-gray-600">Engage</div>-->
                        <!--</a>-->
                        <div class="absolute left-1/2 top-full transform -translate-x-1/2 transition z-500 min-w-[150px]" v-show="submenu == 3" x-transition.opacity v-cloak>
                            <div class="h-6 cursor-default"></div>
                            <ul class="py-1 px-2 bg-white border border-gray-300 rounded-lg shadow-lg flex flex-col justify-start items-start text-left whitespace-nowrap">
                                <li>
                                    <div class="py-1 tracking-widest text-xs text-gray-700 uppercase cursor-default block w-full">Engage</div>
                                </li>
                                <li>
                                    <a class="py-1 transition hover:text-gray-600 block w-full cursor-pointer" @click="openRouterLink('index')">Liquidity Mining and Staking</a>
                                </li>
                                <li>
                                    <a href="https://www.fundrs.app/" class="py-1 transition hover:text-gray-600 block w-full">Fundrs</a>
                                </li>
                                <li>
                                    <a href="https://abdex.io/" class="py-1 transition hover:text-gray-600 block w-full">DEX</a>
                                </li>
                                <li>
                                    <a href="https://alliancebridge.io/" class="py-1 transition hover:text-gray-600 block w-full">Bridge</a>
                                </li>
                                <li>
                                    <a href="https://allianceblock.io/datatunnel/" class="py-1 transition hover:text-gray-600 block w-full mb-4">Data Tunnel</a>
                                </li>
                                <!--<li>
                                    <a href="https://defiterm.io/terminal" class="py-1 transition hover:text-gray-600 block w-full mb-4">Terminal</a>
                                </li>-->
                                <li>
                                    <div class="py-1 tracking-widest text-xs text-gray-700 uppercase cursor-default block w-full">Documentation</div>
                                </li>
                                <li>
                                    <a href="https://support.allianceblock.io/support/solutions" class="py-1 transition hover:text-gray-600 block w-full">Knowledge Base</a>
                                </li>
                                <!--<li>
                                    <a href="#" class="py-1 transition hover:text-gray-600 block w-full">Academy</a>
                                </li>-->
                                <!--<li>
                                    <a href="#" class="py-1 transition hover:text-gray-600 block w-full">Marketplace</a>
                                </li>-->
                            </ul>
                        </div>
                    </button>
                    <button class="inline-flex items-center p-3 text-sm tracking-widest font-bold text-gray-400 cursor-pointer transition relative group" @mouseover="submenu = 4" @mouseleave="submenu = 0">
                        <!--<a href="#">-->
                            <div class="transition group-hover:text-gray-600" :class="{'text-gray-600': $route.name === 'build'}">Build</div>
                        <!--</a>-->
                        <div class="absolute left-1/2 top-full transform -translate-x-1/2 transition z-500 min-w-[150px]" v-show="submenu == 4" x-transition.opacity v-cloak>
                            <div class="h-6 cursor-default"></div>
                            <ul class="py-1 px-2 bg-white border border-gray-300 rounded-lg shadow-lg flex flex-col justify-start items-start text-left whitespace-nowrap">
                                <li>
                                    <div class="py-1 tracking-widest text-xs text-gray-700 uppercase cursor-default block w-full">Grow</div>
                                </li>
                                <li>
                                    <a class="py-1 transition hover:text-gray-600 block w-full cursor-pointer" @click="openRouterLink('build')">Liquidity Mining and Staking</a>
                                </li>
                                <li>
                                    <a href="https://www.fundrs.app/" class="py-1 transition hover:text-gray-600 block w-full">Fundrs</a>
                                </li>
                                <li>
                                    <a href="https://abdex.io/" class="py-1 transition hover:text-gray-600 block w-full">DEX</a>
                                </li>
                                <li>
                                    <a href="https://alliancebridge.io/" class="py-1 transition hover:text-gray-600 block w-full mb-4">Bridge</a>
                                </li>
                                <li>
                                    <div class="py-1 tracking-widest text-xs text-gray-700 uppercase cursor-default block w-full">Build</div>
                                </li>
                                <li>
                                    <a href="https://blog.allianceblock.io/abdefiterm-getting-started-with-defi-terminal-sdk-f4904cd98f94" target="_blank" class="py-1 transition hover:text-gray-600 block w-full">Liquidity Mining and Staking SDK</a>
                                </li>
                                <!--<li>
                                    <a href="#" class="py-1 transition hover:text-gray-600 block w-full">Bridge SDK (LINK!)</a>
                                </li>
                                <li>
                                    <a href="#" class="py-1 transition hover:text-gray-600 block w-full">DEX SDK (LINK!)</a>
                                </li>-->
                            </ul>
                        </div>
                    </button>
                    <a v-if="$route.name === 'build'" @click="openRouterLink('contact')">
                        <button class="inline-flex items-center p-3 text-sm tracking-widest font-bold text-gray-400 cursor-pointer transition hover:text-gray-600" :class="{'text-gray-600': $route.name === 'contact'}">Contact</button>
                    </a>
                    <a href="https://allianceblock.io/contact" v-if="$route.name !== 'build'">
                        <button class="inline-flex items-center p-3 text-sm tracking-widest font-bold text-gray-400 cursor-pointer transition hover:text-gray-600">Contact</button>
                    </a>
                </nav>
                <div class="w-6 h-5 flex flex-col justify-center items-center md:hidden cursor-pointer relative" @click="menuOpen = !menuOpen; submenu = 0;" v-cloak>
                    <div class="w-full h-0.5 absolute left-0 bg-gray-400 transition transition-all duration-500" v-bind:class="menuOpen ? '-rotate-45 top-1/2 -translate-y-1/2' : 'top-0' "></div>
                    <div class="w-full h-0.5 bg-gray-400 transition duration-500" v-bind:class="menuOpen ? 'scale-0' : ''"></div>
                    <div class="w-full h-0.5 absolute left-0 bg-gray-400 transition transition-all duration-500" v-bind:class="menuOpen ? 'rotate-45 bottom-1/2 translate-y-1/2' : 'bottom-0' "></div>
                </div>
            </div>
            <!-- Mobile menu -->
            <div class="w-full h-screen absolute top-0 left-0 -z-1 p-4 pt-20 flex flex-col md:hidden bg-white transition duration-500" v-bind:class="menuOpen ? '' : '-translate-y-full opacity-0'" v-cloak>
                <ul class="w-full h-full flex flex-col items-center overflow-y-auto">
                    <!--<a href="#">-->
                        <button class="m-2 transition transition-all duration-300 delay-500 inline-flex items-center p-3 text-sm tracking-widest font-bold text-gray-400 cursor-pointer transition hover:text-gray-600" style="transition-delay: 500ms;" v-bind:class="menuOpen ? '' : 'opacity-0 -translate-x-8'" @click="submenu = submenu === 1 ? 0 : 1">About</button>
                    <!--</a>-->
                    <ul class="text-xs text-center text-gray-500 -mt-3 mb-2" v-show="submenu == 1" x-transition.opacity v-cloak>
                        <li>
                            <a href="https://allianceblock.io/ecosystem" class="py-1 transition hover:text-gray-600 block w-full">Ecosystem</a>
                        </li>
                        <li>
                            <a href="https://allianceblock.io/team" class="py-1 transition hover:text-gray-600 block w-full">Team</a>
                        </li>
                        <li>
                            <a href="https://allianceblock.io/token" class="py-1 transition hover:text-gray-600 block w-full">Token</a>
                        </li>
                        <li>
                            <a href="https://allianceblock.io/use-cases" class="py-1 transition hover:text-gray-600 block w-full">Use cases</a>
                        </li>
                        <li>
                            <a href="https://allianceblock.io/roadmap" class="py-1 transition hover:text-gray-600 block w-full">Roadmap</a>
                        </li>
                    </ul>
                    <div class="w-4/5 h-px bg-gray-100"></div>
                    <!--<a href="#">-->
                        <button class="m-2 transition transition-all duration-300 delay-550 inline-flex items-center p-3 text-sm tracking-widest font-bold text-gray-400 cursor-pointer transition hover:text-gray-600" style="transition-delay: 550ms;" v-bind:class="menuOpen ? '' : 'opacity-0 -translate-x-8'" @click="submenu = submenu === 2 ? 0 : 2">Media</button>
                    <!--</a>-->
                    <ul class="text-xs text-center text-gray-500 -mt-3 mb-2" v-show="submenu == 2" x-transition.opacity v-cloak>
                        <li>
                            <a href="https://allianceblock.io/news" class="py-1 transition hover:text-gray-600 block w-full">News</a>
                        </li>
                        <li>
                            <a href="https://allianceblock.io/blogs" class="py-1 transition hover:text-gray-600 block w-full mb-4">Blogs</a>
                        </li>
                        <li>
                            <div class="py-1 tracking-widest text-xs text-gray-700 uppercase cursor-default block w-full">Documentation</div>
                        </li>
                        <li>
                            <a href="/files/AllianceBlock_Whitepaper.pdf" class="py-1 transition hover:text-gray-600 block w-full" target="_blank">White Paper</a>
                        </li>
                        <li>
                            <a href="/files/AllianceBlock_DEX_Whitepaper.pdf" class="py-1 transition hover:text-gray-600 block w-full" target="_blank">DEX White Paper</a>
                        </li>
                        <li>
                            <a href="/files/AllianceBlock_Greenpaper.pdf" class="py-1 transition hover:text-gray-600 block w-full" target="_blank">Green Paper</a>
                        </li>
                        <li>
                            <a href="/files/AllianceBlock_Token_Economics_Paper.pdf" class="py-1 transition hover:text-gray-600 block w-full" target="_blank">Token Economics Paper</a>
                        </li>
                        <!--<li>
                            <a href="https://allianceblock.io/AllianceBlock_OnePager.pdf" class="py-1 transition hover:text-gray-600 block w-full" target="_blank">One Pager</a>
                        </li>-->
                    </ul>
                    <div class="w-4/5 h-px bg-gray-100"></div>
                    <!--<a href="#">-->
                        <button class="m-2 transition transition-all duration-300 delay-600 inline-flex items-center p-3 text-sm tracking-widest font-bold text-gray-400 cursor-pointer transition hover:text-gray-600" style="transition-delay: 600ms;" v-bind:class="menuOpen ? '' : 'opacity-0 -translate-x-8'" @click="submenu = submenu === 3 ? 0 : 3">Engage</button>
                    <!--</a>-->
                    <ul class="text-xs text-center text-gray-500 -mt-3 mb-2" v-show="submenu == 3" x-transition.opacity v-cloak>
                        <li>
                            <div class="py-1 tracking-widest text-xs text-gray-700 uppercase cursor-default block w-full">Products</div>
                        </li>
                        <li>
                            <a class="py-1 transition hover:text-gray-600 block w-full cursor-pointer" @click="openRouterLink('index')">Liquidity Mining and Staking</a>
                        </li>
                        <li>
                            <a href="https://www.fundrs.app/" class="py-1 transition hover:text-gray-600 block w-full">Fundrs</a>
                        </li>
                        <li>
                            <a href="https://abdex.io/" class="py-1 transition hover:text-gray-600 block w-full">DEX</a>
                        </li>
                        <li>
                            <a href="https://alliancebridge.io/" class="py-1 transition hover:text-gray-600 block w-full">Bridge</a>
                        </li>
                        <li>
                            <a href="https://allianceblock.io/datatunnel/" class="py-1 transition hover:text-gray-600 block w-full mb-4">Data Tunnel</a>
                        </li>
                        <!--<li>
                            <a href="#" class="py-1 transition hover:text-gray-600 block w-full mb-4">Terminal</a>
                        </li>-->
                        <li>
                            <div class="py-1 tracking-widest text-xs text-gray-700 uppercase cursor-default block w-full">Documentation</div>
                        </li>
                        <li>
                            <a href="https://support.allianceblock.io/support/solutions" class="py-1 transition hover:text-gray-600 block w-full">Knowledge Base</a>
                        </li>
                        <!--<li>
                            <a href="#" class="py-1 transition hover:text-gray-600 block w-full">Academy</a>
                        </li>
                        <li>
                            <a href="#" class="py-1 transition hover:text-gray-600 block w-full">Marketplace</a>
                        </li>-->
                    </ul>
                    <div class="w-4/5 h-px bg-gray-100"></div>
                    <button class="m-2 transition transition-all duration-300 delay-650 inline-flex items-center p-3 text-sm tracking-widest font-bold text-gray-400 cursor-pointer transition hover:text-gray-600" style="transition-delay: 650ms;" v-bind:class="menuOpen ? '' : 'opacity-0 -translate-x-8'" @click="submenu = submenu === 4 ? 0 : 4">Build</button>
                    <ul class="text-xs text-center text-gray-500 -mt-3 mb-2" v-show="submenu == 4" x-transition.opacity v-cloak>
                        <li>
                            <div class="py-1 tracking-widest text-xs text-gray-700 uppercase cursor-default block w-full">Grow</div>
                        </li>
                        <li>
                            <a class="py-1 transition hover:text-gray-600 block w-full cursor-pointer" @click="openRouterLink('build')">Liquidity Mining and Staking</a>
                        </li>
                        <li>
                            <a href="https://www.fundrs.app/" class="py-1 transition hover:text-gray-600 block w-full">Fundrs</a>
                        </li>
                        <li>
                            <a href="https://abdex.io/" class="py-1 transition hover:text-gray-600 block w-full">DEX</a>
                        </li>
                        <li>
                            <a href="https://alliancebridge.io/" class="py-1 transition hover:text-gray-600 block w-full mb-4">Bridge</a>
                        </li>
                        <li>
                            <div class="py-1 tracking-widest text-xs text-gray-700 uppercase cursor-default block w-full">Build</div>
                        </li>
                        <li>
                            <a href="https://blog.allianceblock.io/abdefiterm-getting-started-with-defi-terminal-sdk-f4904cd98f94" target="_blank" class="py-1 transition hover:text-gray-600 block w-full mb-4">Liquidity Mining and Staking SDK</a>
                        </li>
                    </ul>
                    <div class="w-4/5 h-px bg-gray-100"></div>
                    <a v-if="$route.name === 'build'" @click="openRouterLink('contact')">
                        <button class="m-2 transition transition-all duration-300 delay-700 inline-flex items-center p-3 text-sm tracking-widest font-bold text-gray-400 cursor-pointer transition hover:text-gray-600" style="transition-delay: 700ms;" v-bind:class="menuOpen ? '' : 'opacity-0 -translate-x-8'">Contact</button>
                    </a>
                    <a href="https://allianceblock.io/contact" v-if="$route.name !== 'build'">
                        <button class="m-2 transition transition-all duration-300 delay-700 inline-flex items-center p-3 text-sm tracking-widest font-bold text-gray-400 cursor-pointer transition hover:text-gray-600" style="transition-delay: 700ms;" v-bind:class="menuOpen ? '' : 'opacity-0 -translate-x-8'">Contact</button>
                    </a>
                </ul>
            </div>
            <!-- Additional menu bar -->
            <div class="w-full items-center justify-between py-3 border-b border-gray-300 hidden sm:flex" v-if="!menuOpen && ($route.name === 'index' || $route.name === 'build')">
                <div class="flex items-center justify-start space-x-6">
                    <p class="text-lg font-black text-aspect">DeFi Terminal</p>
                    <p class="text-lg font-black text-aspect">&middot;</p>
                    <div class="flex items-center space-x-4">
                        <div class="button-default global-hover font-black" style="box-shadow: none;" v-bind:class="$route.name === 'index' ? 'text-aspect bg-aspect/10' : 'text-decent'" @click="openRouterLink('index')">Participate</div>
                        <div class="button-default global-hover font-black" style="box-shadow: none;" v-bind:class="$route.name === 'build' ? 'text-aspect bg-aspect/10' : 'text-decent'" @click="openRouterLink('build')">Build</div>
                    </div>
                </div>
                <div class="button-default button--primary global-hover" @click="openRouterLink('contact')" v-if="$route.name === 'build'">Create your campaign</div>
            </div>
        </div>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-2 flex flex-col space-y-4" v-else>
            <div class="w-full flex items-center justify-between py-3">
                <a href="https://allianceblock.io">
                    <img src="./../assets/logo.png" class="h-[40px]" title="AllianceBlock" />
                </a>
                <nav class="hidden md:flex md:items-center gap-8" v-cloak>
                    <button class="inline-flex items-center p-3 text-sm tracking-widest font-bold text-gray-400 cursor-pointer transition relative group">
                        <a href="#metrics">
                            <div class="transition group-hover:text-gray-600">Metrics</div>
                        </a>
                    </button>
                    <button class="inline-flex items-center p-3 text-sm tracking-widest font-bold text-gray-400 cursor-pointer transition relative group">
                        <a href="#clients">
                            <div class="transition group-hover:text-gray-600">Clients</div>
                        </a>
                    </button>
                    <a href="https://meetings.hubspot.com/jochem-herber/gerben-jochem-defi-terminal">
                        <button class="button-default button--primary global-hover">Book Demo Call</button>
                    </a>
                </nav>
            </div>
        </div>
    </header>
    <!--</div>-->

</template>

<script>

    export default {
        name: 'MainHeader',
        props: {

        },
        data() {
            return {
                menuOpen: false,
                submenu: 0,
            }
        },
        methods: {
            openRouterLink: function(routeName) {
                this.menuOpen = false;
                this.submenu = 0;
                this.$router.push({ name: routeName });
            },
        }
    }

</script>

<style scoped>

</style>
