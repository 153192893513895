<template>

    <section class="w-full my-24 p-4 lg:p-0">
        <HeadingDecent title="Customized Portal" />
        <div class="w-[600px] max-w-full mx-auto mt-2">
            <HeadingSecondary title="Your brand, your platform" />
        </div>
        <p class="w-[600px] max-w-full mx-auto text-decent text-center mt-2" style="font-size: 1.2rem;">DeFi Terminal makes it <strong>easy to setup and launch</strong> your <strong>branded</strong> liquidity mining or single-sided staking campaigns, all <strong>within an hour</strong>. Setup and deploy campaigns across <strong>all supported networks and DEXes</strong> without touching any code.</p>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6 items-center">
            <img class="aspect-video w-full" src="./../assets/your-platform2.png" />
            <p class="text-decent max-w-[415px] mx-auto">Customize the duration and length of your campaigns, assign rewards, and schedule them in advance.<br /><br />With access to a number of powerful analytics tools, you can optimize your strategy and allocate rewards more efficiently.<br /><br />Through the dashboard, you can also customize your portal with four themes and primary colors to fit your brand—make it truly your brand, your platform.</p>
        </div>
    </section>

</template>

<script>

import HeadingDecent from './HeadingDecent.vue';
import HeadingSecondary from './HeadingSecondary.vue';

export default {
    name: 'CustomizationSection',
    components: {
        HeadingDecent,
        HeadingSecondary,
    }
}

</script>

<style scoped>



</style>
