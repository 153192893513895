<template>

    <section class="flex flex-col pt-12 px-6 lg:px-0 lg:items-center lg:justify-between space-y-6 lg:space-y-12">
        <div class="flex flex-col space-y-6 text-center">
            <h1 class="text-aspect font-semibold max-w-[900px] mx-auto gradient-text"><strong class="font-black">Liquidity mining</strong> and <strong class="font-black">staking campaigns</strong>, your way</h1>
            <div class="flex flex-col space-y-6">
                <p class="text-lg text-decent max-w-[450px] mx-auto">Launch your campaigns in <strong>your brand</strong>, using <strong>no code</strong>, within <strong>an hour</strong>, and with <strong>full ownership</strong> of your smart contracts</p>
                <div class="w-full items-center justify-center flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                    <router-link :to="{ name: 'contact' }">
                        <button class="button-default button--primary global-hover">Create your campaign</button>
                    </router-link>
                    <a href="#problem">
                        <button class="button-default button--secondary global-hover">Learn more</button>
                    </a>
                </div>
            </div>
            <!--<form action="#" class="mt-12 sm:max-w-lg sm:w-full sm:flex" @submit.prevent="submitEmail()">
                <div class="min-w-0 flex-1">
                    <label for="hero-email" class="sr-only">Email address</label>
                    <input id="hero-email" type="email" class="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" placeholder="Enter your email" v-model="email">
                </div>
                <div class="mt-4 sm:mt-0 sm:ml-3">
                    <button type="submit" class="block w-full rounded-md border border-transparent px-5 py-3 bg-aspect text-base font-medium text-white shadow bg-opacity-90 hover:bg-opacity-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:px-10 transition">Get started</button>
                </div>
            </form>-->
        </div>
        <div class="bg-aspect rounded-xl flex justify-center items-center aspect-video w-[800px] max-w-full shadow-lg cursor-pointer overflow-hidden">
            <iframe class="w-full h-full" src="https://www.youtube.com/embed/b5daYKPiK2g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <!--<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32 0C14.25 0 0 14.375 0 32C0 49.75 14.25 64 32 64C49.625 64 64 49.75 64 32C64 14.375 49.625 0 32 0ZM46.5 34.625L28.5 45.625C28 45.875 27.5 46 27 46C25.25 46 24 44.625 24 43V21C24 19.5 25.25 18 27 18C27.5 18 28 18.25 28.5 18.5L46.5 29.5C47.375 30 48 31 48 32C48 33.125 47.375 34.125 46.5 34.625Z" fill="white"/>
            </svg>-->
        </div>
    </section>

</template>

<script>

export default {
    name: 'BuildOpener',
    components: {

    },
    data() {
        return {
            email: '',
        }
    },
    methods: {
        submitEmail: function() {
            if (this.email.length > 0) {
                this.$router.push({ name: 'contact', params: { email: this.email }});
            }
        },
    }
}

</script>

<style scoped>



</style>
