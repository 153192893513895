<template>

    <section class="flex flex-col lg:flex-row pt-24 pb-36 px-6 lg:px-0 lg:items-center lg:justify-between space-y-6 lg:space-y-0 text-center sm:text-left">
        <h1 class="text-aspect font-bold gradient-text">Stake your assets<br />and earn rewards</h1>
        <div class="flex flex-col space-y-6">
            <p class="text-lg text-decent lg:text-right"><strong>Provide liquidity</strong> to pools or<br /><strong>stake your assets</strong> to earn rewards.</p>
            <div class="w-full items-center justify-start lg:justify-end flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                <!--<a href="#data-table">-->
                <router-link :to="{ name: 'contact' }">
                    <button class="button-default button--primary global-hover">Get started</button>
                </router-link>
                <!--</a>-->
                <a href="#metrics">
                    <button class="button-default button--secondary global-hover">Learn more</button>
                </a>
            </div>
        </div>
    </section>

</template>

<script>

export default {
    name: 'MainOpener',
    components: {

    },
}

</script>

<style scoped>



</style>
