<template>

    <section class="w-full bg-aspect rounded-xl my-24 flex flex-col lg:flex-row items-center justify-between z-10">
        <div class="p-8 text-center lg:text-left">
            <h2 class="text-2xl text-white font-bold mb-1">Do you have a token? Create your campaigns with the AllianceBlock DeFi Terminal</h2>
            <p class="text-gray-200 mb-4">Get your liquidity mining or staking campaign setup within an hour — no code, branded, and secure</p>
            <div class="w-full flex items-center justify-center lg:justify-start space-x-4 font-[SpaceGrotesk]">
                <a href="https://meetings.hubspot.com/jochem-herber/gerben-jochem-defi-terminal">
                    <button class="bg-white py-2 px-3 hover:-transform-translate-y-px active:transform-translate-y-px rounded-2xl capitalize text-aspect font-bold button-secondary global-hover cta-button">
                        <span class="lg:hidden">Book demo call</span>
                        <span class="hidden lg:block">Book a 10-minutes demo call to learn more</span>
                    </button>
                </a>
            </div>
        </div>
        <img src="./../assets/dashboard-ui.png" class="" alt="Dashboard UI" />
    </section>

</template>

<script>

export default {
    name: 'CTABanner2',
    components: {

    }
}

</script>

<style scoped>

</style>
