<template>

    <section class="w-full flex flex-col lg:flex-row items-center justify-center gap-8">
        <a href="https://blog.allianceblock.io/abdefiterm-staking-use-case-for-launchpads-e9e313b20682" target="_blank">
            <div class="p-4 rounded-xl shadow bg-aspect text-white flex flex-col space-y-3 items-center max-w-[400px] global-hover">
                <h4 class="w-full font-semibold text-sm tracking-widest uppercase">Use case</h4>
                <p>How <span class="font-black">launchpads</span> can help cultivate a sustainable launch for their projects and their ecosystem</p>
                <div class="w-full flex justify-end">
                    <button class="font-semibold text-sm tracking-widest uppercase hover:underline text-gray-200">Read more</button>
                </div>
            </div>
        </a>
        <a href="https://blog.allianceblock.io/abdefiterm-staking-usecase-for-gamefi-1930de111979" target="_blank">
            <div class="p-4 rounded-xl shadow bg-aspect text-white flex flex-col space-y-3 items-center max-w-[400px] global-hover">
                <h4 class="w-full font-semibold text-sm tracking-widest uppercase">Use case</h4>
                <p>How <span class="font-black">GameFi</span> can leverage staking solutions to create a more immersive game</p>
                <div class="w-full flex justify-end">
                    <button class="font-semibold text-sm tracking-widest uppercase hover:underline text-gray-200">Read more</button>
                </div>
            </div>
        </a>
        <a href="https://blog.allianceblock.io/abdefiterm-staking-and-liquidity-mining-for-new-projects-6a10e7f30417" target="_blank">
            <div class="p-4 rounded-xl shadow bg-aspect text-white flex flex-col space-y-3 items-center max-w-[400px] global-hover">
                <h4 class="w-full font-semibold text-sm tracking-widest uppercase">Use case</h4>
                <p>How <span class="font-black">early startups</span> can stabilize their liquidity and accelerate their roadmap</p>
                <div class="w-full flex justify-end">
                    <button class="font-semibold text-sm tracking-widest uppercase hover:underline text-gray-200">Read more</button>
                </div>
            </div>
        </a>
    </section>

</template>

<script>

export default {
    name: 'UseCases',
    components: {

    }
}

</script>

<style scoped>

</style>
