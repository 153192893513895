<template>

    <section id="clients" class="w-full my-24 scroll-mt-36">
        <HeadingSecondary title="Clients" v-if="!showTextContent && !hideHeadline" />
        <HeadingDecent title="Our Clients" v-if="showTextContent" />
        <div class="w-[900px] max-w-full mx-auto mt-2" v-if="showTextContent">
            <HeadingSecondary title="Tried, Trusted and Improved" />
        </div>
        <div :class="{'grid grid-cols-1 lg:grid-cols-3 gap-8 mt-6 items-center': showTextContent}">
            <div class="flex flex-col space-y-8 mt-8 col-span-2">
                <div class="mx-auto flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-8 justify-center items-center">
                    <img class="h-12 md:h-6" :class="{'lg:h-8': !showTextContent, '2xl:h-12': !showTextContent}" src="./../assets/third-party-logos/dafi.png" alt="DAFI" />
                    <img class="h-12 md:h-6" :class="{'lg:h-8': !showTextContent, '2xl:h-12': !showTextContent}" src="./../assets/third-party-logos/hypersign.png" alt="Hypersign" />
                    <img class="h-12 md:h-6" :class="{'lg:h-8': !showTextContent, '2xl:h-12': !showTextContent}" src="./../assets/third-party-logos/terra-virtua.png" alt="Terra Virtua" />
                    <img class="h-12 md:h-6" :class="{'lg:h-8': !showTextContent, '2xl:h-12': !showTextContent}" src="./../assets/third-party-logos/lokr.png" alt="LOKR" />
                </div>
                <div class="mx-auto flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-8 justify-center items-center">
                    <img class="h-12 md:h-6" :class="{'lg:h-8': !showTextContent, '2xl:h-12': !showTextContent}" src="./../assets/third-party-logos/genesis-shards.png" alt="Genesis Shards" />
                    <img class="h-12 md:h-6" :class="{'lg:h-8': !showTextContent, '2xl:h-12': !showTextContent}" src="./../assets/third-party-logos/polinate.png" alt="Polinate" />
                    <img class="h-12 md:h-6" :class="{'lg:h-8': !showTextContent, '2xl:h-12': !showTextContent}" src="./../assets/third-party-logos/ix-swap.png" alt="IX Swap" />
                    <img class="h-12 md:h-6" :class="{'lg:h-8': !showTextContent, '2xl:h-12': !showTextContent}" src="./../assets/third-party-logos/ore-network.png" alt="ORE Network" />
                </div>
                <div class="mx-auto flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-8 justify-center items-center">
                    <img class="h-12 md:h-6" :class="{'lg:h-8': !showTextContent, '2xl:h-12': !showTextContent}" src="./../assets/third-party-logos/crypto-xpress.png" alt="Crypto XPress" />
                    <img class="h-12 md:h-6" :class="{'lg:h-8': !showTextContent, '2xl:h-12': !showTextContent}" src="./../assets/third-party-logos/transient.png" alt="Transient" />
                    <img class="h-16 md:h-8" :class="{'lg:h-12': !showTextContent, '2xl:h-16': !showTextContent}" src="./../assets/third-party-logos/vault-hill.png" alt="Vault Hill" />
                    <img class="h-12 md:h-6" :class="{'lg:h-8': !showTextContent, '2xl:h-12': !showTextContent}" src="./../assets/third-party-logos/enjinstarter.png" alt="Enjinstarter" />
                </div>
                <div class="mx-auto flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-8 justify-center items-center">
                    <img class="h-12 md:h-6" :class="{'lg:h-8': !showTextContent, '2xl:h-12': !showTextContent}" src="./../assets/third-party-logos/hai-damo.png" alt="Hai Damo" />
                    <img class="h-16 md:h-8" :class="{'lg:h-12': !showTextContent, '2xl:h-16': !showTextContent}" src="./../assets/third-party-logos/glitch.png" alt="Glitch" />
                    <img class="h-12 md:h-6" :class="{'lg:h-8': !showTextContent, '2xl:h-12': !showTextContent}" src="./../assets/third-party-logos/ritestream.png" alt="Ritestream" />
                    <img class="h-8 md:h-4" :class="{'lg:h-6': !showTextContent, '2xl:h-8': !showTextContent}" src="./../assets/third-party-logos/peculium.png" alt="Peculium" />
                </div>
                <div class="mx-auto flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-8 justify-center items-center">
                    <img class="h-12 md:h-6" :class="{ 'lg:h-8': !showTextContent, '2xl:h-12': !showTextContent }" src="./../assets/third-party-logos/galileoprotocol-color.svg" alt="Galileo Protocol" />
                    <img class="h-16 md:h-6" :class="{ 'lg:h-8': !showTextContent, '2xl:h-12': !showTextContent }" src="./../assets/third-party-logos/bosonprotocol-color.svg" alt="Boson Protocol" />
                    <img class="h-12 md:h-6" :class="{ 'lg:h-8': !showTextContent, '2xl:h-12': !showTextContent }" src="./../assets/third-party-logos/fluus-color.svg" alt="Fluus" />
                </div>
            </div>
            <p class="text-center lg:text-left w-[500px] mx-auto lg:w-auto text-decent" v-if="showTextContent">With more than 16 clients up to date—and more in the pipeline—our clients have utilized our liquidity mining and staking solutions to raise or lock their liquidity across multiple networks.<br /><br />On average, our clients have seen more than twice the growth of liquidity, and almost all have extended their campaigns and created additional ones.</p>
        </div>
    </section>

</template>

<script>

import HeadingDecent from './HeadingDecent.vue';
import HeadingSecondary from './HeadingSecondary.vue';

export default {
    name: 'ClientsList',
    components: {
        HeadingDecent,
        HeadingSecondary,
    },
    props: [
        'hideHeadline',
        'showTextContent',
    ],
}

</script>

<style scoped>

</style>
