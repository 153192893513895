<template>

    <h2 class="text-center text-aspect uppercase tracking-widest font-bold gradient-text text-3xl">{{ title }}</h2>

</template>

<script>

export default {
    name: 'HeadingSecondary',
    props: {
        title: String
    }
}

</script>

<style scoped>

</style>
