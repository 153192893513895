<template>

    <div class="">
        <div class="p-4 transition flex justify-center items-center">
            <img :src="image" class="w-full h-auto max-w-[150px]" />
        </div>
        <div class="">
            <h3 class="min-h-[56px] text-gray-600 font-bold text-lg text-center mb-4">{{ title }}</h3>
            <p class="text-decent text-center">{{ description }}</p>
        </div>
    </div>

</template>

<script>

export default {
    name: 'ImagePanel',
    components: {

    },
    props: [
        'title',
        'description',
        'image',
    ],
}

</script>

<style scoped>



</style>
