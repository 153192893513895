<template>

    <section id="metrics" class="w-full my-36 scroll-mt-36">
        <!--<HeadingSecondary title="Metrics" />-->
        <div class="w-full mt-8 grid grid-cols-2 md:grid-cols-4 gap-8 text-aspect">
            <div class="flex flex-col items-center">
                <div class="flex justify-center items-center space-x-2 mb-1">
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 0.75C3.11719 0.75 0 3.89453 0 7.75C0 11.6328 3.11719 14.75 7 14.75C10.8555 14.75 14 11.6328 14 7.75C14 3.89453 10.8555 0.75 7 0.75ZM9.35156 9.36328C9.21484 10.1289 8.55859 10.6484 7.65625 10.7852V11.25C7.65625 11.6328 7.35547 11.9062 7 11.9062C6.61719 11.9062 6.34375 11.6328 6.34375 11.25V10.7578C5.93359 10.7031 5.55078 10.5664 5.19531 10.457L5.03125 10.4023C4.67578 10.293 4.51172 9.91016 4.62109 9.58203C4.73047 9.22656 5.08594 9.03516 5.44141 9.17188L5.60547 9.22656C5.96094 9.33594 6.31641 9.44531 6.61719 9.5C7.38281 9.60938 8.01172 9.44531 8.06641 9.11719C8.12109 8.84375 8.01172 8.70703 6.80859 8.40625L6.58984 8.32422C5.90625 8.16016 4.32031 7.72266 4.62109 6.16406C4.75781 5.39844 5.41406 4.87891 6.34375 4.74219V4.25C6.34375 3.89453 6.61719 3.59375 7 3.59375C7.35547 3.59375 7.65625 3.89453 7.65625 4.25V4.74219C7.90234 4.76953 8.14844 4.85156 8.50391 4.96094C8.83203 5.07031 9.02344 5.42578 8.91406 5.78125C8.80469 6.10938 8.44922 6.30078 8.09375 6.19141C7.79297 6.10938 7.54688 6.02734 7.32812 6C6.58984 5.89062 5.96094 6.05469 5.90625 6.38281C5.87891 6.60156 5.82422 6.76562 6.91797 7.06641L7.16406 7.12109C8.09375 7.36719 9.65234 7.77734 9.35156 9.36328Z" fill="#5466FD"/>
                    </svg>
                    <p>Total Value Locked</p>
                </div>
                <p class="text-4xl font-black blur animate-pulse cursor-wait" v-if="tvl.lm === null || tvl.staking === null || tvl.stakingAdditional === null">XX.XX</p>
                <p class="text-4xl font-black" v-html="numberWithCommas(parseInt(tvl.lm + tvl.staking + tvl.stakingAdditional))" v-else></p>
                <p>$ USD</p>
            </div>
            <div class="flex flex-col items-center">
                <div class="flex justify-center items-center space-x-2 mb-1">
                    <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.21875 7.75C2.21875 5.34375 2.98438 3.375 3.96875 3.375C4.92578 3.375 5.71875 5.34375 5.71875 7.75C5.71875 10.1836 4.92578 12.125 3.96875 12.125C2.98438 12.125 2.21875 10.1836 2.21875 7.75ZM8.125 7.75C8.125 11.6328 6.34766 14.75 4.1875 14.75C2 14.75 0.25 11.6328 0.25 7.75C0.25 3.89453 2 0.75 4.1875 0.75C6.34766 0.75 8.125 3.89453 8.125 7.75ZM5.99219 11.168C6.375 10.2656 6.59375 9.0625 6.59375 7.75C6.59375 6.46484 6.375 5.26172 5.99219 4.33203C5.82812 3.89453 5.58203 3.45703 5.28125 3.12891C4.95312 2.80078 4.51562 2.5 3.96875 2.5C3.39453 2.5 2.95703 2.80078 2.62891 3.12891C2.32812 3.45703 2.08203 3.89453 1.91797 4.33203C1.53516 5.26172 1.34375 6.46484 1.34375 7.75C1.34375 9.0625 1.53516 10.2656 1.91797 11.168C2.08203 11.6328 2.32812 12.0703 2.62891 12.3984C2.95703 12.7266 3.39453 13 3.96875 13C4.51562 13 4.95312 12.7266 5.28125 12.3984C5.58203 12.0703 5.82812 11.6328 5.99219 11.168ZM6.42969 14.75C6.83984 14.3945 7.22266 13.957 7.52344 13.4375H9.27344C8.69922 14.2852 7.98828 14.75 7.25 14.75H6.42969ZM9.76562 12.5625H8.01562C8.37109 11.7969 8.64453 10.9219 8.80859 9.9375H10.5586C10.3945 10.9492 10.1211 11.8242 9.76562 12.5625ZM8.91797 9.0625C8.97266 8.65234 9 8.21484 9 7.75C9 7.3125 8.97266 6.875 8.91797 6.4375H10.668C10.7227 6.875 10.75 7.3125 10.75 7.75C10.75 8.21484 10.7227 8.65234 10.668 9.0625H8.91797ZM10.5586 5.5625H8.80859C8.64453 4.60547 8.37109 3.73047 8.01562 2.9375H9.76562C10.1211 3.70312 10.3945 4.57812 10.5586 5.5625ZM7.52344 2.0625C7.22266 1.57031 6.83984 1.13281 6.42969 0.75H7.25C7.98828 0.75 8.69922 1.24219 9.27344 2.0625H7.52344Z" fill="#5466FD"/>
                    </svg>
                    <p>TVL in Liquidity Mining</p>
                </div>
                <p class="text-4xl font-black blur animate-pulse cursor-wait" v-if="tvl.lm === null">XX.XX</p>
                <p class="text-4xl font-black" v-html="numberWithCommas(parseInt(tvl.lm))" v-else></p>
                <p>$ USD</p>
            </div>
            <div class="flex flex-col items-center">
                <div class="flex justify-center items-center space-x-2 mb-1">
                    <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.21875 7.75C2.21875 5.34375 2.98438 3.375 3.96875 3.375C4.92578 3.375 5.71875 5.34375 5.71875 7.75C5.71875 10.1836 4.92578 12.125 3.96875 12.125C2.98438 12.125 2.21875 10.1836 2.21875 7.75ZM8.125 7.75C8.125 11.6328 6.34766 14.75 4.1875 14.75C2 14.75 0.25 11.6328 0.25 7.75C0.25 3.89453 2 0.75 4.1875 0.75C6.34766 0.75 8.125 3.89453 8.125 7.75ZM5.99219 11.168C6.375 10.2656 6.59375 9.0625 6.59375 7.75C6.59375 6.46484 6.375 5.26172 5.99219 4.33203C5.82812 3.89453 5.58203 3.45703 5.28125 3.12891C4.95312 2.80078 4.51562 2.5 3.96875 2.5C3.39453 2.5 2.95703 2.80078 2.62891 3.12891C2.32812 3.45703 2.08203 3.89453 1.91797 4.33203C1.53516 5.26172 1.34375 6.46484 1.34375 7.75C1.34375 9.0625 1.53516 10.2656 1.91797 11.168C2.08203 11.6328 2.32812 12.0703 2.62891 12.3984C2.95703 12.7266 3.39453 13 3.96875 13C4.51562 13 4.95312 12.7266 5.28125 12.3984C5.58203 12.0703 5.82812 11.6328 5.99219 11.168ZM6.42969 14.75C6.83984 14.3945 7.22266 13.957 7.52344 13.4375H9.27344C8.69922 14.2852 7.98828 14.75 7.25 14.75H6.42969ZM9.76562 12.5625H8.01562C8.37109 11.7969 8.64453 10.9219 8.80859 9.9375H10.5586C10.3945 10.9492 10.1211 11.8242 9.76562 12.5625ZM8.91797 9.0625C8.97266 8.65234 9 8.21484 9 7.75C9 7.3125 8.97266 6.875 8.91797 6.4375H10.668C10.7227 6.875 10.75 7.3125 10.75 7.75C10.75 8.21484 10.7227 8.65234 10.668 9.0625H8.91797ZM10.5586 5.5625H8.80859C8.64453 4.60547 8.37109 3.73047 8.01562 2.9375H9.76562C10.1211 3.70312 10.3945 4.57812 10.5586 5.5625ZM7.52344 2.0625C7.22266 1.57031 6.83984 1.13281 6.42969 0.75H7.25C7.98828 0.75 8.69922 1.24219 9.27344 2.0625H7.52344Z" fill="#5466FD"/>
                    </svg>
                    <p>TVL in Staking</p>
                </div>
                <p class="text-4xl font-black blur animate-pulse cursor-wait" v-if="tvl.staking === null || tvl.stakingAdditional === null">XX.XX</p>
                <p class="text-4xl font-black" v-html="numberWithCommas(parseInt(tvl.staking + tvl.stakingAdditional))" v-else></p>
                <p>$ USD</p>
            </div>
            <!--<div class="flex flex-col items-center">
                <div class="flex justify-center items-center space-x-2 mb-1">
                    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.25 0.625C12.7148 0.625 13.125 1.03516 13.125 1.5C13.125 1.99219 12.7148 2.375 12.25 2.375H2.1875C1.94141 2.375 1.75 2.59375 1.75 2.8125C1.75 3.05859 1.94141 3.25 2.1875 3.25H12.25C13.207 3.25 14 4.04297 14 5V11.125C14 12.1094 13.207 12.875 12.25 12.875H1.75C0.765625 12.875 0 12.1094 0 11.125V2.375C0 1.41797 0.765625 0.625 1.75 0.625H12.25ZM11.375 8.9375C11.8398 8.9375 12.25 8.55469 12.25 8.0625C12.25 7.59766 11.8398 7.1875 11.375 7.1875C10.8828 7.1875 10.5 7.59766 10.5 8.0625C10.5 8.55469 10.8828 8.9375 11.375 8.9375Z" fill="#5466FD"/>
                    </svg>
                    <p>Wallets Connected (LM)</p>
                </div>
                <p class="text-4xl font-black blur animate-pulse cursor-wait" v-if="uniqueWallets.lm === null">XX.XX</p>
                <p class="text-4xl font-black" v-html="parseInt(uniqueWallets.lm)" v-else></p>
            </div>-->
            <div class="flex flex-col items-center">
                <div class="flex justify-center items-center space-x-2 mb-1">
                    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.75 10.6875C1.75 10.9336 1.94141 11.125 2.1875 11.125H13.125C13.5898 11.125 14 11.5352 14 12C14 12.4922 13.5898 12.875 13.125 12.875H2.1875C0.957031 12.875 0 11.918 0 10.6875V1.5C0 1.03516 0.382812 0.625 0.875 0.625C1.33984 0.625 1.75 1.03516 1.75 1.5V10.6875ZM12.0039 7.37891L11.1016 6.50391L8.91406 8.69141C8.58594 9.04688 8.01172 9.04688 7.68359 8.69141L5.6875 6.69531L4.10156 8.25391C3.77344 8.60938 3.19922 8.60938 2.87109 8.25391C2.51562 7.92578 2.51562 7.35156 2.87109 7.02344L5.05859 4.83594C5.38672 4.48047 5.96094 4.48047 6.28906 4.83594L8.3125 6.83203L9.87109 5.27344L8.96875 4.37109C8.55859 3.96094 8.85938 3.25 9.43359 3.25H12.4688C12.8242 3.25 13.125 3.55078 13.125 3.90625V6.94141C13.125 7.51562 12.4141 7.81641 12.0039 7.37891Z" fill="#5466FD"/>
                    </svg>
                    <p>Active Campaigns</p>
                </div>
                <p class="text-4xl font-black blur animate-pulse cursor-wait" v-if="activeCampaigns.lm === null || activeCampaigns.staking === null">XX.XX</p>
                <p class="text-4xl font-black" v-html="parseInt(activeCampaigns.lm + activeCampaigns.staking + 2)" v-else></p>
            </div>
        </div>
    </section>

</template>

<script>

import axios from 'axios';
// import HeadingSecondary from './HeadingSecondary.vue';

export default {
    name: 'DataMetrics',
    components: {
        // HeadingSecondary,
    },
    data() {
        return {
            apiPath: 'https://api.defiterm.io/dashboard/campaigns',
            tvl: {
                lm: null,
                staking: null,
                stakingAdditional: null,
            },
            uniqueWallets: {
                lm: null,
                staking: null,
            },
            activeCampaigns: {
                lm: null,
                staking: null,
            },
        };
    },
    mounted() {
        axios.get(this.apiPath + '/lm/aggregated', {
            "Access-Control-Allow-Origin": "*",
        }).then(response => {
            this.tvl.lm = response.data.totalStakedUSD;
            this.uniqueWallets.lm = response.data.walletsUnique.length;
        });

        axios.get(this.apiPath + '/staking/aggregated', {
            "Access-Control-Allow-Origin": "*",
        }).then(response => {
            this.tvl.staking = response.data.totalStakedUSD;
            // this.uniqueWallets.staking = response.data.walletsUnique.length;
        });

        axios.get(this.apiPath + '/lm/active', {
            "Access-Control-Allow-Origin": "*",
        }).then(response => {
            this.activeCampaigns.lm = response.data.length;
        });

        axios.get(this.apiPath + '/staking/active', {
            "Access-Control-Allow-Origin": "*",
        }).then(response => {
            this.activeCampaigns.staking = response.data.length;
        });

        // this.tvl.lm = 2338456.09;
        // this.tvl.staking = 4252000.48;
        // API metric staking + HNM Staking campaign (20'000'000 x ALBT price coingecko) + V1 (13'000'000 x ALBT price coingecko)

        axios.get('https://api.coingecko.com/api/v3/coins/allianceblock', {
            "Access-Control-Allow-Origin": "*",
        }).then(response => {
            if (response.data && response.data.market_data && response.data.market_data.current_price && response.data.market_data.current_price.usd) {
                let tokenPrice = response.data.market_data.current_price.usd
                this.tvl.stakingAdditional = 20000000 * tokenPrice + 13000000 * tokenPrice;
            }
        }).catch(error => {
            console.log(error);
            this.tvl.staking = 4252000.48;
        });

        // this.uniqueWallets.lm = 733;
        this.uniqueWallets.staking = null;
        // this.activeCampaigns.lm = 44;
        // this.activeCampaigns.staking = 0;
    },
    methods: {
        numberWithCommas: function(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    }
}

</script>

<style scoped>

</style>
