<template>

    <p class="text-center text-decent font-bold subhead">{{ title }}</p>

</template>

<script>

export default {
    name: 'HeadingDecent',
    props: {
        title: String
    }
}

</script>

<style scoped>

</style>
