<template>

    <a :href="url" target="_blank">
        <div class="h-full rounded-lg overflow-hidden bg-white shadow global-hover box-secondary transition" style="border: 1px solid #bbc2fe;">
            <div class="bg-aspect aspect-video cursor-pointer transition hover:bg-opacity-90">
                <img :src="image" class="w-full h-auto object-cover" />
            </div>
            <div class="p-8">
                <h3 class="text-gray-600 font-bold text-lg mb-1">{{ title }}</h3>
                <p class="text-decent">{{ description }}</p>
            </div>
        </div>
    </a>

</template>

<script>

export default {
    name: 'TutorialTile',
    components: {

    },
    props: [
        'image',
        'title',
        'description',
        'url',
    ],
}

</script>

<style scoped>



</style>
