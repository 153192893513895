<template>

    <section class="w-full flex flex-col lg:flex-row items-center justify-center gap-8">
        <a href="https://alliancebridge.io/" target="_blank">
            <div class="p-4 rounded-xl shadow bg-white flex items-center max-w-[400px] global-hover" style="border: 1px solid #bbc2fe;">
                <div>
                    <h4 class="font-medium text-aspect text-xl"><span class="font-black">Bridge</span> your tokens across multiple networks</h4>
                    <button class="bg-aspect text-white rounded-full py-2 px-3 mt-4 global-hover">Bridge</button>
                </div>
                <img class="" src="./../assets/bridge.png" />
            </div>
        </a>
        <a href="https://abdex.io/" target="_blank">
            <div class="p-4 rounded-xl shadow bg-white flex items-center max-w-[400px] global-hover" style="border: 1px solid #bbc2fe;">
                <div>
                    <h4 class="font-medium text-aspect text-xl"><span class="font-black">Swap</span> tokens and <span class="font-black">provide liquidity</span> to pools</h4>
                    <button class="bg-aspect text-white rounded-full py-2 px-3 mt-4 global-hover">DEX</button>
                </div>
                <img class="" src="./../assets/trade.png" />
            </div>
        </a>
    </section>

</template>

<script>

export default {
    name: 'CTAPanels',
    components: {

    }
}

</script>

<style scoped>

</style>
