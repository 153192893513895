<template>

    <div class="p-1 snap-start">
        <div class="rounded-xl bg-white shadow p-8 flex justify-between items-start space-x-12 w-[450px] md:w-[650px] global-hover box-secondary" style="border: 1px solid #bbc2fe;">
            <div class="w-[150px] md:w-[250px]">
                <img class="max-h-[75px] w-auto" :src="image" alt="" />
                <!--<p class="w-full mt-8 text-black font-black text-4xl">{{ value1 }}</p>
                <p class="w-full mt-1 text-decent text-sm">{{ value1Label }}</p>
                <p class="w-full mt-8 text-black font-black text-4xl">{{ value2 }}</p>
                <p class="w-full mt-1 text-decent text-sm">{{ value2Label }}</p>-->
            </div>
            <div class="w-[300px] md:w-[400px]">
                <p class="w-full text-decent">{{ text }}</p>
                <p class="w-full mt-4 text-black font-black text-xl">{{ author }}</p>
                <p class="w-full mt-px text-black font-semibold text-base">{{ authorPosition }}</p>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: 'TestimonialTile',
    components: {

    },
    props: [
        'image',
        'value1',
        'value1Label',
        'value2',
        'value2Label',
        'text',
        'author',
        'authorPosition',
    ],
}

</script>

<style scoped>



</style>
