<template>

    <div class="max-w-full max-h-[80vh] fixed bottom-[120px] right-5 md:right-[150px] z-[1001] rounded-lg border border-gray-300" v-show="isShowing">
        <div class="relative">
            <iframe
                style="--border-radius: 10px; box-shadow: 0 2px 10px 0 rgba(0,0,0,.20); border-radius: var(--border-radius); margin: auto;max-width: 420px"
                src="https://widget.onramper.com?color=5466FD&defaultCrypto=eth&wallets=ETH:&apiKey=pk_prod_7_fQCwnznKgjVVC17cTH_B2XMVGUiYa1LPtJkVqma8M0"
                height="660px"
                width="482px"
                title="Onramper widget"
                frameborder="0"
                allow="accelerometer; autoplay; camera; gyroscope; payment">
            </iframe>
            <div class="absolute top-1 right-1 cursor-pointer text-gray-500 hover:text-gray-800 transition" @click="isShowing = false">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
    </div>

    <button class="py-2 px-3 bg-gray-300 text-gray-800 fixed bottom-[32px] right-[150px] rounded-full rounded-tr-[5px] flex items-center space-x-2 z-[1000] cursor-pointer transition duration-500 hover:scale-105" @click="isShowing = !isShowing" v-cloak>
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.838 11.674C19.934 11.128 20 10.573 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C10.881 20 11.731 19.874 12.546 19.659C12.437 19.529 12.309 19.416 12.21 19.278C11.897 18.839 11.648 18.361 11.453 17.861C10.981 17.948 10.496 18 10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C14.411 2 18 5.589 18 10C18 10.354 17.974 10.702 17.927 11.043C17.784 11.029 17.646 11 17.5 11C15.019 11 13 13.019 13 15.5C13 17.981 15.019 20 17.5 20C19.981 20 22 17.981 22 15.5C22 13.878 21.129 12.466 19.838 11.674ZM17.5 18C16.122 18 15 16.878 15 15.5C15 14.122 16.122 13 17.5 13C18.878 13 20 14.122 20 15.5C20 16.878 18.878 18 17.5 18ZM11 14V11H14V9H11V6H9V9H6V11H9V14H11Z" fill="#484A57"/>
        </svg>
        <p>Get Tokens</p>
    </button>

</template>

<script>

export default {
    name: 'OnRamperWidget',
    components: {

    },
    data() {
        return {
            isShowing: false,
        }
    },
    methods: {

    }
}

</script>

<style scoped>



</style>
